import React, { Fragment, useCallback, useContext, useEffect } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import isFunction from 'lodash/isFunction';

import Header from './Header'
import Box from '../components/Box';
import theme from '../components/ThemeProvider/theme';
import customThemeContext from '../components/ThemeProvider/customThemeContext';

const Layout = ({ children, noHeader, themeOverride }) => {
  const { overrideTheme } = useContext(customThemeContext);
  useEffect(() => {
    if (themeOverride) {
      overrideTheme(themeOverride);
    }
  }, [themeOverride])
  const memoChild = useCallback(({ title, description, url }) => (
    <Fragment>
      {!noHeader && <Header height={theme.headerHeight} siteTitle={title} />}
      <Box height="100vh" pt={!noHeader && theme.headerHeight}>
        {isFunction(children) ? children({ title, description, url }) : children}
      </Box>
    </Fragment>
  ), [noHeader, children])
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title,
              description,
              url
            }
          }
        }
      `}
      render={({ site: { siteMetadata: { title, description, url } } }) => (
        <div>
          <Helmet>
            <html lang="zh-Hant" />
            <title>{title}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no"></meta>
            <meta name="description" content={description} />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="mask-icon" color="#5bbad" href="/safari-pinned-tab.svg" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
          </Helmet>
          {memoChild({ title, description, url })}
        </div>
      )}
    />
  )
}

Layout.defaultProps = {
  noHeader: true,
}

export default Layout
