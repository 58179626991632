import React from 'react';
import PropTypes from 'prop-types';

import range from 'lodash/range';

import Flex from './Flex';
import Box from './Box';
import Text from './Text';

const Progress = ({ total, current, bg, height, ...props }) => total ? (
  <Box {...props}>
    <Flex
      width={1}
      py="0.25em"
      // border="1px solid"
      // borderColor="gray"
    >
      {range(total).map((i) => (
        <Box
          key={i}
          width={1}
          height={height}
          bg={i > (current - 1) ? 'variations.gray.2' : bg}
          mx="0.25em"
        />
      ))}
    </Flex>
    <Text align="center" mt="0.25em" color="variations.gray.7">{current} / {total}</Text>
  </Box>
) : null;

Progress.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number,
  bg: PropTypes.string,
};

Progress.defaultProps = {
  height: '0.5em',
  bg: 'primary',
};

export default Progress;
