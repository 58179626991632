import React from 'react'
import Box from '../components/Box';
import Progress from '../components/Progress';

const Header = ({ total, current, ...props }) => (
  <Box
    position="fixed"
    top={0}
    left={0}
    right={0}
    zOrder={2}
    bg="white"
    {...props}
  >
    <Progress total={total} current={current} />
  </Box>
)

export default Header;
